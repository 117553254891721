.App {
  text-align: center;
}

/* App.css */
.AllComponents {
  /* Style for the container div that holds your content */
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white background for content */
  max-width: 1000px; /* Set the maximum width for your content container */
  width: 100%;
  padding: 20px;
  /* Additional styling as needed */
}

@media screen and (max-width: 1500px)
{
  .AllComponents {
    max-width: 800px;
    width: 100%;
    padding: 20px;
  }
}

@media screen and (max-width: 900px)
{
  .AllComponents {
    max-width: 500px;
    width: 100%;
    padding: 20px;
  }
}

@media screen and (max-width: 600px)
{
  .AllComponents {
    max-width: 300px;
    width: 100%;
    padding: 20px;
  }
}

@media screen and (max-width: 400px)
{
  .AllComponents {
    max-width: 250px;
    width: 100%;
    padding: 0px;
  }
}

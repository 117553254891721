.contact-info {
    text-align: center;
    line-height: 0.5;
    max-height: 125px;
}

.profile-picture {
    position: relative;
    top: -150px;
    left: 220px;
    width: 200px;
    height: 150px;
    border: 1px solid black;
    border-radius: 200px;
}


.objective-statement {
    line-height: 0.7;
}

.education {
    line-height: 0.4;
}

.education-list {
    line-height: 1.4;
}

.relevant-coursework {
    line-height: 0.7;
}

.achievements-certifications {
    line-height: 1.2;
}

.work-experience {
    line-height: 0.7;
}

.work-experience-list {
    line-height: 1.4;
}

.projects {
    line-height: 1.5;
}

.space-container {
    position: relative;
    left: 600px;
}

.space-container-2 {
    position: relative;
    left: 575px;
}

.space-container-3 {
    position: relative;
    left: 725px;
}

.space-container-4 {
    position: relative;
    left: 675px;
}

h1 {
    font-size: 30px;
    text-decoration: underline;
}

h2 {
    text-align: left;
    text-decoration: underline;
    background: linear-gradient(to bottom, #00bdff 0%, #66ffae 100%);
    display: flex;
    border: 2px solid black;
    border-radius: 5px;
    padding: 15px;
}

@media screen and (max-width: 1500px) 
{
    .objective-statement {
        line-height: 1;
    }

    .space-container {
        position: relative;
        left: 500px;
    }
    
    .space-container-2 {
        position: relative;
        left: 475px;
    }
    
    .space-container-3 {
        position: relative;
        left: 625px;
    }
    
    .space-container-4 {
        position: relative;
        left: 575px;
    }
}

@media screen and (max-width: 900px) 
{
    .profile-picture {
        position: relative;
        top: -140px;
        left: 200px;
        width: 175px;
        height: 125px;
        border: 1px solid black;
        border-radius: 200px;
    }

    .objective-statement {
        line-height: 1.2;
    }

    .relevant-coursework {
        line-height: 1.4;
    }

    .space-container {
        position: relative;
        left: 200px;
    }
    
    .space-container-2 {
        position: relative;
        left: 175px;
    }
    
    .space-container-3 {
        position: relative;
        left: 325px;
    }
    
    .space-container-4 {
        position: relative;
        left: 275px;
    }
}

@media screen and (max-width: 600px) 
{
    .profile-picture {
        position: relative;
        top: -110px;
        left: 160px;
        width: 100px;
        height: 75px;
        border: 1px solid black;
        border-radius: 200px;
    }

    .education {
        line-height: 1.2;
    }

    .space-container {
        position: relative;
        left: 20px;
    }
    
    .space-container-2 {
        position: relative;
        left: 0px;
    }
    
    .space-container-3 {
        position: relative;
        left: 125px;
    }
    
    .space-container-4 {
        position: relative;
        left: 75px;
    }
}

@media screen and (max-width: 450px) 
{
    .profile-picture {
        display: none;
    }

    .achievements-certifications {
        line-height: 1.5;
    }

    .projects {
        line-height: 2.0;
    }
}

@media screen and (max-width: 400px)
{
    .space-container {
        position: relative;
        left: 0px;
    }

    .space-container-3 {
        position: relative;
        left: 75px;
    }
    
    .space-container-4 {
        position: relative;
        left: 25px;
    }

    .spacing-required {
        display: none;
    }
}